import sv from "./sv.json";
import en from "./en.json";

const dictionaryList = { sv, en };

const languageOptions = {
    sv: "Swedish",
    en: "English"
};

export { dictionaryList, languageOptions };
